import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function highlightsList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refHighlightsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'title',
      label: 'Highlights title',
      sortable: true,
      thStyle: { width: '15%' },
    },
    {
      key: 'description',
      label: 'description',
      sortable: true,
      thStyle: { width: '20%' },
    },
    {
      key: 'date',
      label: 'date & time',
      sortable: true,
    },
    {
      key: 'location',
      label: 'location',
      sortable: true,
      thStyle: { width: '10%' },
    },
    { key: 'status', sortable: true },
    {
      key: 'createdAt',
      label: 'created on',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalHighlights = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'title')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref(route.query.status ?? null)
  const toDeletedID = ref(null)
  const currentDataLength = ref(0)
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refHighlightsTable.value ? refHighlightsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalHighlights.value,
    }
  })

  const refetchData = () => {
    refHighlightsTable.value.refresh()
  }

  watch([searchQuery, statusFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchHighlights = (ctx, callback) => {
    store
      .dispatch('app-highlights/fetchHighlights', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      },
      { root: true })
      .then(response => {
        const { highlights, total } = response.data
        currentDataLength.value = highlights.length
        callback(highlights)
        totalHighlights.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteHighlights = () => {
    store
      .dispatch('app-highlights/deleteHighlights', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveHighlightsStatusVariant = status => {
    if (status === 'published') return 'success'
    return 'secondary'
  }

  return {
    fetchHighlights,
    tableColumns,
    perPage,
    currentPage,
    totalHighlights,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refHighlightsTable,

    resolveHighlightsStatusVariant,
    refetchData,

    toDeletedID,
    deleteHighlights,
    currentDataLength,

    // Extra Filters
    statusFilter,
  }
}
